<template>
  <!--begin::Advance Table Widget 9-->
  <div class="card">
    <!--begin::Header-->
    <div class="card-header border-0 py-5 d-flex justify-content-between">
      <div class="font-weight-bolder text-primary text-uppercase font-size-h2" v-if="members_count == 0">
         Users
      </div>
      <div class="font-weight-bolder text-primary text-uppercase font-size-h2" v-else>
         Users ({{ members_count }})
      </div>
      <div class="d-flex">
        <div>
          <b-button variant="primary"  pill class="btn btn-primary py-2 mt-1 mr-4"  @click="showModal">
          New
        </b-button>
        </div>
        <b-form-input v-model="search_text" placeholder="Search..." ></b-form-input>
      </div>
    </div>
    <!--end::Header-->
    <!--begin::Body-->
    <div class="card-body pt-0 pb-3">
      <ag-grid-vue
                rowHeight="65"
                class="ag-theme-material ag-grid-vue"
                :columnDefs="columnDefs"
                :pagination="false"
                @grid-ready="onGridReady"
                :defaultColDef="defaultColDef"
                :frameworkComponents="frameworkComponents"
                :rowData="items"
                >

            </ag-grid-vue>
    </div>
    <b-modal ref="my-modal" hide-footer :title="edit_or_create ? 'Add User' : 'Update User'">
      <v-row>
      <v-col cols="10" offset="1">
        <label>First Name:</label>
        <b-form-input v-model="formData.first_name" placeholder="First Name" class="modal-input"></b-form-input>
        <div v-if="errors && errors.first_name && errors.first_name.length >0">
           <span class="text-danger" v-for="(error, i) in errors.first_name">{{ error }}</span>
        </div>
      </v-col>
    </v-row>
      <v-row>
      <v-col cols="10" offset="1">
        <label>Last Name:</label>
        <b-form-input v-model="formData.last_name" placeholder="Last Name" class="modal-input"></b-form-input>
        <div v-if="errors && errors.last_name && errors.last_name.length >0">
           <span class="text-danger" v-for="(error, i) in errors.last_name">{{ error }}</span>
        </div>
      </v-col>
    </v-row>
       <v-row>
      <v-col cols="10" offset="1">
        <label>Email:</label>
        <b-form-input v-model="formData.email" placeholder="Email" class="modal-input" :disabled="!edit_or_create"></b-form-input>
        <div v-if="errors && errors.email && errors.email.length >0">
           <span class="text-danger" v-for="(error, i) in errors.email">{{ error }}</span>
        </div>
      </v-col>
    </v-row>
       <v-row>
         <v-col cols="10" offset="1" class="pb-0 mb-0">
        <div class="d-flex">
          <label>Admin:</label>
          <b-form-checkbox  v-model="formData.admin" class="ml-2 "></b-form-checkbox>
        </div>
        <div v-if="errors && errors.admin && errors.admin.length >0">
           <span class="text-danger" v-for="(error, i) in errors.admin">{{ error }}</span>
        </div>
      </v-col>
      </v-row>
       <v-row>
         <v-col cols="10" offset="1" class="pb-0 mb-0">
        <div class="d-flex">
          <label>Use Own Teamwork data:</label>
          <b-form-checkbox  v-model="formData.use_own" class="ml-2 "></b-form-checkbox>
        </div>
        <div v-if="errors && errors.use_own && errors.use_own.length >0">
           <span class="text-danger" v-for="(error, i) in errors.use_own">{{ error }}</span>
        </div>
      </v-col>
      </v-row>
      <v-row>
      <v-col cols="10" offset="1" style="text-align: right">
         <b-button variant="primary" pill :disabled="checkForm" @click="submitForm">Submit</b-button>
      </v-col>
    </v-row>
   </b-modal>

    <b-modal ref="my-modal-2"  title="Confirm">
      <div class="d-block text-center">
        <h3>Are you sure to delete this user?</h3>
      </div>
      <template #modal-footer="{ ok, cancel, hide }">
      <b-button size="sm" variant="success" @click="cancel()">
        No
      </b-button>
      <b-button size="sm" variant="danger" @click="deleteConfirm()">
        Yes
      </b-button>
    </template>
    </b-modal>


  </div>
</template>

<script>
import {mapGetters, mapState} from "vuex";
import {GET_USERS, CREATE_USER, UPDATE_USER, DELETE_USER} from "@/core/services/store/profile.module";

import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-material.css";
import { AgGridVue } from 'ag-grid-vue';

import UserActionsRenderer from './Render/UserActionsRenderer.js';
import NameRenderer from './Render/NameRenderer.js';
import { LicenseManager } from "ag-grid-enterprise";
import 'ag-grid-enterprise';

LicenseManager.setLicenseKey("Servoy_B.V_Servoy_7Devs_200Deployment_11_October_2020__MTYwMjM3MDgwMDAwMA==26d908e26f73b44683ba7a5dfdd00755");

export default {
  name: "transactions",
  components: {
     AgGridVue,
  },
  data() {
    return {
      search_text: '',
      members_count: 0,
      deleteConfirmDialog: true,
      selectedPk: null,
      formData:{
        'first_name' : '',
        'last_name' : '',
        'email' : '',
        'admin' : false,
        'use_own' : false,
      },
      txtFile: null,
      items: [],
      gridApi: null,
      columnApi: null,
      defaultColDef: {
        sortable: true,
        suppressMenu: true,
        flex: 1,
      },
      frameworkComponents : null,
      edit_or_create: true
    };
  },
  computed: {
    ...mapState({
      errors: state => state.profile.errors
    }),
    ...mapGetters(["currentUserAccountInfo", "layoutConfig"]),
    checkForm(){
      return !(this.formData.first_name !== null  && this.formData.last_name !== null)
    },
     columnDefs() {
      if(this.currentUserAccountInfo.admin)
       return [
         {
           headerName: 'First Name',
           field: 'first_name',
           cellStyle: {
             fontWeight: 500,
             textAlign: 'center',
           },
         },
         {
           headerName: 'Last Name',
           field: 'last_name',
           cellStyle: {
             textAlign: 'center',
           },
         },
         {
           headerName: 'Email',
           field: 'email',
           cellStyle: {
             textAlign: 'center',
           },
         },
         {
           headerName: 'Admin',
           field: 'is_superuser',
           valueFormatter: superUserFormatter,
           cellStyle: {
             textAlign: 'center',
           },
         },

         {
           headerName: 'Use Own',
           field: 'use_own',
           valueFormatter: superUserFormatter,
           cellStyle: {
             textAlign: 'center',
           },
         },

         {
           headerName: 'Actions',
           cellRenderer: 'actionsRenderer',
           cellRendererParams: {
             edit: this.editStatement.bind(this),
             deleteS: this.deleteStatement.bind(this)
           },
           cellStyle: {
             textAlign: 'center',
           },
         }
       ];
       else
         return [
         {
           headerName: 'First Name',
           field: 'first_name',
           cellStyle: {
             fontWeight: 500,
             textAlign: 'center',
           },
         },
         {
           headerName: 'Last Name',
           field: 'last_name',
           cellStyle: {
             textAlign: 'center',
           },
         },
         {
           headerName: 'Email',
           field: 'email',
           cellStyle: {
             textAlign: 'center',
           },
         },
         {
           headerName: 'Admin',
           field: 'is_superuser',
           valueFormatter: superUserFormatter,
           cellStyle: {
             textAlign: 'center',
           },
         },
         {
           headerName: 'Use Own',
           field: 'use_own',
           valueFormatter: superUserFormatter,
           cellStyle: {
             textAlign: 'center',
           },
         },
       ];
     },
  },
  methods: {
     deleteConfirm(){
      this.$refs['my-modal-2'].hide()
      this.$store
          .dispatch(DELETE_USER, this.selectedPk)
          .then((data) => {
            this.hideModal2()
            this.loadUsers()
          });
    },
     hideModal() {
       this.$refs['my-modal'].hide()
     },
    editStatement(data){
      this.formData = {
        'pk' : data.pk,
        'first_name' : data.first_name,
        'last_name' : data.last_name,
        'email' : data.email,
        'admin' : data.is_superuser,
        'use_own' : data.use_own,
      };
       this.edit_or_create = false;
       this.$refs['my-modal'].show()
    },
    deleteStatement(id){
     this.$refs['my-modal-2'].show()
     this.selectedPk = id;
    },
     hideModal2() {
       this.$refs['my-modal-2'].hide()
     },
    showModal() {
      this.formData = {
        'first_name' : '',
        'last_name' : '',
        'email' : '',
        'admin' : false,
        'use_own' : false,
      };
       this.edit_or_create = true;
       this.$refs['my-modal'].show()
    },
    submitForm(){
       if(this.edit_or_create){
         this.$store
          .dispatch(CREATE_USER, this.formData)
          .then((data) => {
            this.hideModal()
            this.loadUsers()
          });
       }
       else{
         this.$store
          .dispatch(UPDATE_USER, this.formData)
          .then((data) => {
            this.hideModal()
            this.loadUsers()
          });
       }
     },
    autoSizeAll(skipHeader) {
      const allColumnIds = [];
      this.gridColumnApi.getAllColumns().forEach((column) => {
        allColumnIds.push(column.getId());
      });
      this.gridColumnApi.autoSizeColumns(allColumnIds, skipHeader);
    },
    onGridReady(params) {
      this.gridApi = params.api;
      this.gridColumnApi = params.columnApi;
      this.loadUsers();
     },
    loadUsers(){
         this.$store
          .dispatch(GET_USERS, null)
          .then((data) => {
            this.items = data;
            this.members_count = this.items.length;
            this.gridApi.hideOverlay();
          })
      }
  },
  beforeMount() {
     this.frameworkComponents = {
       'actionsRenderer': UserActionsRenderer,
       'nameRenderer' : NameRenderer
     };
  },
  watch: {
    search_text(new_value, old_value) {
       this.gridApi.setQuickFilter(new_value);
    },

  },
  created() {
    this.loadUsers();
  },
};

window.currencyFormatter = function currencyFormatter(params) {
  return '$' + params.value;
};

window.companyFormatter = function companyFormatter(params) {
  if(params.value) return params.value;
  return '-';
};

window.superUserFormatter = function superUserFormatter(params) {
  if(params.value) return "Yes";
  return 'No';
};


window.phoneFormatter = function phoneFormatter(params) {
  if(!params.value) return '-';
  var cleaned = ('' + params.value).replace(/\D/g, '');
  var match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    return [ '(', match[2], ') ', match[3], '-', match[4]].join('');
  }
  return params.value
};

</script>
<style>
.ag-header-cell-label {
   justify-content: center;
}
 .btn-create {
    margin-left: 10px;
    border-radius: 33px;
    height: 28px;
    padding: 0rem 1rem!important;
 }
 .btn-create i{
    font-size: 1rem!important;
    padding-right: 0.1rem!important;
 }
 .modal-input{
   height: 45px!important;
 }
  .ag-grid-vue{
       width: 100%!important;
       height: calc(100vh - 230px)!important;
    }

    /* This CSS is to not apply the border for the column having 'no-border' class */
    .no-border.ag-cell:focus{
        border:none !important;
        outline: none;
    }
    .ag-cell-focus,.ag-cell-no-focus{
        border:none !important;
    }

    .ag-row-selected {
        background-color: transparent!important;
    }
    .ag-theme-material .ag-details-row {
       padding: 10px 40px 10px 40px!important;
    }

    div.ag-theme-material div.ag-row {
      font-size: 15px !important;
      padding-top: 10px!important;
    }
</style>
